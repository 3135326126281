.alt-font {
  font-family: 'Playfair Display', serif;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  display: inline-block;
  background-color: #0058A6;
  line-height: 1.375;
  font-family: inherit;
  cursor: pointer;
  color: #fff;
  padding: 10px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.button:hover,
.button:focus {
  background-color: #CCCCCC;
}
.navigation {
  float: left;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e7e7e7;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  margin-top: auto;
}
div.sub1 > .item > .menu {
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 10px 15px;
  text-decoration: none;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus,
div.sub1 > .item > .menu.path {
  color: #666666;
}
/*# sourceMappingURL=./screen-large.css.map */